/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Si no lo sabíais os confieso que soy un extremo defensor de IPv6, creo que este “nuevo” protocolo de comunicación puede revolucionar la forma en que utilizamos la red, puede crear una red única, cohesionada y móvil, eliminando gran parte de los problemas técnicos que tenemos hoy en día para ofrecer servicios distribuidos a través de Internet."), "\n", React.createElement(_components.p, null, "Es cierto que la implantación de IPv6 también tiene sus puntos negros, con el establecimiento del rango de IPs por persona se podría asumir que la IP identifica a la persona, cosa que hoy en día no es posible, con las consecuencias legales de esto, un paso hacia el panóptico."), "\n", React.createElement(_components.p, null, "Pero aún así creo que IPv6 tiene más ventajas que inconvenientes y vamos a ver como podemos conseguir una IP pública de la versión 6 de este protocolo en nuestra Raspberry. De esta forma conseguiremos una dirección de internet incluso a través de routers con NAT y direcciones IPv4 dinámicas."), "\n", React.createElement(_components.p, null, "Primero entramos en nuestra RPi con SSH como siempre y miramos la dirección de internet que tenemos:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">ifconfig</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Veremos que solo tenemos una dirección IPv4 privada de nuestra red por ejemplo en mi caso es 192.168.0.129."), "\n", React.createElement(_components.p, null, "Raspbian por defecto tiene desactivada IPv6, supongo que para ahorrar recursos, así que vamos a activar el módulo del kernel que gestiona esta función: ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo modprobe ipv6</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Y ahora si volvemos a mirar nuestras IPs veremos que hay una IPv6 privada para nuestra red local, podemos usar esta si no nos salimos de nuestra red, pero queremos una IP pública para toda la Inet6."), "\n", React.createElement(_components.p, null, "Para ello usaremos Miredo, una implementación para Linux de Teredo, un túnel IPv6 sobre IPv4:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">sudo apt-get install miredo</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Y ahora volvemos a mirar nuestras IPs: ", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">ifconfig</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Y veremos algo como esto:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/2f0f620abbcf7ecf188eb48721352aa0/d44c9/teredo.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 30.37974683544304%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAABOklEQVQY0y2LXU+CUABA2dqcIOpDWeBXgPeCpdf7CYKACAhCmprO/v9PaVbbeTjn4Ui56fuPSHSnoYZDjSRDETwtIo3GOg1fSKzTSCN/Get03Wexzu4y4JuRK23GLlPtoIeKyWprLLOxV0xW6djbWkFu+jsQFSAqQVhYQTpyk6GbGcutFQQ95HbfpEXTQg0jffUuvLp5+xMurqK+8OrCqiuvr7w+k/IT5VdR54afDEQy4KI7JQrATUtiKkRNczN2z6y8LfeVs/6YJodZ+r08fNFyB+P9+2YHox2MToti9YxpC2J5QmSAFSDhBpw/mGtdHFF+RFkxWR1RVtnRCRclCEONxH2WDAVTbdqC/6dyP4UKpBDakW2HwPGHM96dUwXQFiQKYKrN2jbvOKzt8M6UKoDIvyh3sAy8NvwB7PlBcY7VMogAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Dirección Inet6 de Teredo.\"\n        title=\"Dirección Inet6 de Teredo.\"\n        src=\"/static/2f0f620abbcf7ecf188eb48721352aa0/f058b/teredo.png\"\n        srcset=\"/static/2f0f620abbcf7ecf188eb48721352aa0/c26ae/teredo.png 158w,\n/static/2f0f620abbcf7ecf188eb48721352aa0/6bdcf/teredo.png 315w,\n/static/2f0f620abbcf7ecf188eb48721352aa0/f058b/teredo.png 630w,\n/static/2f0f620abbcf7ecf188eb48721352aa0/d44c9/teredo.png 722w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Probamos a hacer ping a una dirección IPv6 fiable:"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">ping6 -c 3 ipv6.google.com</code>"
    }
  })), "\n", React.createElement(_components.p, null, "Y nos debería devolver 3 pings sin perderse."), "\n", React.createElement(_components.p, null, "Como vemos el procedimiento es muy sencillo, esto nos da una forma rápida de acceder a Inet6, luego podemos acceder a la Raspberry en Inet6 desde cualquier ordenador únicamente instalando el paquete Miredo como hemos visto."), "\n", React.createElement(_components.p, null, "Por desgracia esto aún no nos da una dirección estática para la Raspberry y es un problema, tendré que estudiar otras posibilidades, como los proveedores de túneles IPv6."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
